import { createSlice} from "@reduxjs/toolkit";
// import axios from "../../http/axios";
import storage from "../../utils/storage";
const userinfo = createSlice({
    name: 'userinfo',
    initialState: {
        username: '',
        account: '',
        password: '',
        allusers: [], // name not username
        usersinfo: [],
        friends: JSON.parse(storage.get('friends')) ? JSON.parse(storage.get('friends')) : [],
        theother: { username: '', account: '', privateHistory: [] }   // 当前私聊对象
    },
    reducers: {
        setAllUsers: (state, action) => {
            state.allusers = action.payload
        },
        setUserInfo: (state, action) => {
            state.usersinfo = action.payload
        },
        setStorage: (state) => {
            storage.set('state', state)
        },
        setUsername: (state, action) => {
            storage.set('username', action.payload)
        },
        getStorage: (state) => {
            state = storage.get('state')
        },
        getUsername(state) {
            state.username = storage.get('username')
            console.log('store' + storage.get('username'))
        },
        setuserinfo(state, action) {
            state.usersinfo = action.payload
        },
        setfriends(state, action) {
            console.log(action.payload);
            state.friends = action.payload
        },
        setFriends(state, action) {
            storage.set('friends', action.payload)
        }
    },
})
export const { setAllUsers, setUserInfo, getUsername, getStorage, setUsername, setStorage, setuserinfo, setfriends, setFriends } = userinfo.actions
export default userinfo