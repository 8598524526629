import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import 'lib-flexible'
import { RouterProvider } from 'react-router-dom';
import store from './store';
import { Provider } from 'react-redux';
import router from './router';
import socket from './socket';
import {Spin} from 'antd'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <Suspense fallback={<Spin></Spin>}>
      <Provider store={store}>
        <RouterProvider router={router} socket={socket} />
      </Provider>
    </Suspense>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

