import { setAllUsers, setuserinfo, setfriends, setFriends } from './store/userInfo/userinfo';
import store from './store';
import { io } from 'socket.io-client'
 // 连接到 Socket.io 服务器地址
const socket = io('https://www.pabzibo.live:3001/');
socket.on('allUsers', (msg) => {
    store.dispatch(setAllUsers(msg))
    let copy = []
    for (var i = 0; i < msg.length; i++) {
        copy[msg[i].account] = msg[i]
    }

    store.dispatch(setuserinfo(copy))
})
socket.on('getLatestPrivateHistory', (data) => {

    store.dispatch(setfriends(data))
    store.dispatch(setFriends(JSON.stringify(data)))


})

export default socket;