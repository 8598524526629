import React, { useEffect, useState } from 'react'
import { PullRefresh, Toast } from 'react-vant'
import axios from '../../http/axios'

import './shou.scss'
import localforage from 'localforage'

export default function () {
  let [list, setlist] = useState([]
  )
  let getall = async () => {
    let res = await axios.get('/look')

     localforage.setItem('tlist',JSON.stringify(res.data.vllist))

    setlist(res.data.vllist)
  }
  const onRefresh = showToast => {

    return new Promise(resolve => {

      setTimeout(() => {
        if (showToast) {
          Toast.info('刷新成功')
        }
        resolve(true)
      }, 1000)
    })
  }
  useEffect(() => {
    getall()
  }, [])

  return (
    <>
      <PullRefresh successText="刷新成功" onRefresh={onRefresh} style={{ overflow: 'auto' }}>

        {list.slice(0,4).map((item, index) => {
                
          return <div key={index} className='ge'>
            <div className='tu'>
              <video className='vido1'  alt="" style={{width:'100%',height:'100%'}} controls>
              <source src={item.video} type="video/mp4" />
              </video>
 
            </div>
            <div className='zi'>
              {item.title}
            </div>
            <div className='bai'>
              <div className='tou'>
                <img src={item.uid.avatar} alt="" className='tt' />
              </div>
              <div className='yh'>
                {item.uid.name}
              </div>
              <div className='wei'>
                <img src='https://www.pabzibo.live:3001/upload/wei.png' />
              </div>
              <div className='zan'>

                111
              </div>
              <div className='yb'>
                <img src="https://www.pabzibo.live:3001/upload/3点.png" className='ydd' />
              </div>
            </div>
          </div>
        })}


      </PullRefresh>


    </>
  )
}

