import { configureStore } from '@reduxjs/toolkit'
import demo from './demo/demo'
import userinfo from './userInfo/userinfo'
const store = configureStore({
    reducer: {
        demo: demo.reducer,
        userinfo: userinfo.reducer
    }
})
export default store
