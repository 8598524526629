import { createBrowserRouter, Navigate } from "react-router-dom";
import React from "react";
// import Demo from "../component/Demo";
import Tj from '../component/zhuye/Tj'
import Bottomtab from "../component/Botom-Tabs/Bottomtab"
const Regist = React.lazy(() => import("../component/Regist"))
const Shou = React.lazy(() => import('../component/zhuye/Shou'))

const Chatting = React.lazy(() => import('../component/Chatting/Chatting'))
const Login = React.lazy(() => import('../component/Login'))
const Send = React.lazy(() => import('../component/Chatting/Send'))

const VideoPlayer = React.lazy(() => import('../component/VideoPlayer/VideoPlayer'))

const Mine = React.lazy(() => import('../component/Mine/Mine'))
const Map = React.lazy(() => import('../component/Mine/Map'))
const Ip = React.lazy(() => import('../component/Mine/In'))
function Islogin(module) {
    if (sessionStorage.getItem('token')) {
        return module.children
    } else {
        return <Navigate to='/login'></Navigate>
    }
}

const Gz = React.lazy(() => import('../component/zhuye/Gz'))

const Xsp = React.lazy(() => import('../component/zhuye/Xsp'))
const Publish = React.lazy(() => import('../component/Publish/Publish'))

const router = createBrowserRouter([
    {
        path: '/',
        element: <Navigate to='/yinmeng'></Navigate>
    },
    {
        path: '/yinmeng',
        element: <Bottomtab></Bottomtab>,
        children: [
            {
                path: '/yinmeng',
                element: <Navigate to='/yinmeng/home'></Navigate>,
            },
            {
                path: '/yinmeng/home',
                element: <Shou></Shou>,
                children: [
                    { path: '/yinmeng/home', element: <Navigate to='/yinmeng/home/recommendation'></Navigate> },
                    { path: '/yinmeng/home/follow', element: <Gz></Gz> },
                    { path: '/yinmeng/home/recommendation', element: <Tj></Tj> },
                    { path: '/yinmeng/home/minivideo', element: <Xsp></Xsp> },


                ]
            }, {
                path: '/yinmeng/mine',
                element: <Islogin><Mine></Mine></Islogin>,
            }, {
                path: '/yinmeng/todo',
                element: <Islogin><Publish></Publish></Islogin>,
            }

        ]
    },
    {
        // 视频播放
        path: '/videoplayer',
        element: <Islogin><VideoPlayer></VideoPlayer></Islogin>,
    },
    { path: '/map', element: <Map></Map> },
    { path: '/ip', element: <Ip></Ip> },
    {
        path: '/login',
        element: <Login></Login>,
    },
    {
        path: '/chatting',
        element: <Send></Send>,
    }

    ,
    { path: '/regist', element: <Regist></Regist> },
    { path: '/login', element: <Login></Login> },
    {
        path: '/chattinglist',
        element: <Islogin><Chatting></Chatting></Islogin>,
    }

])
export default router;