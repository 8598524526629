import axios from "axios";

import { Toast} from 'react-vant'
axios.defaults.baseURL = "https://www.pabzibo.live:3001/";


//请求拦截器
// Add a request interceptor
let obj = {}
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    obj = Toast.loading({
        message: '加载中...',
        forbidClick: true,
    })
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});


//响应拦截器
// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    obj.clear()
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});
export default axios;