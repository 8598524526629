//首页底部tab栏
import React, { useEffect, useMemo} from 'react'
import './btottomtab.scss'
import { Outlet, useLocation } from 'react-router-dom'

import { useNavigate } from 'react-router-dom/dist'

export default function Bottomtab() {
    const nav = useNavigate()
    const location = useLocation()
    const ulstyle = useMemo(() => {
        if (location.pathname.includes('/yinmeng/todo')) {
            return { display: 'none' }
        } else {
            return { display: 'block' }
        }
    })
    useEffect(() => {
    }, [])
    return (
        <div className='bgc'>
            <div className="main">
                <Outlet />
            </div>
            <div className="bottomtabs">
                <div style={ulstyle}>
                    <ul>
                        <li onClick={() => { nav('/yinmeng/home') }}><div className="l"><img src={location.pathname.includes('home') ? 'https://www.pabzibo.live:3001/upload/hkhk.png' : 'https://www.pabzibo.live:3001/upload/hk.png'} alt="" /><span>好看</span></div></li>
                        <li onClick={() => { nav('/yinmeng/todo') }}><div className="l"><img src="https://www.pabzibo.live:3001/upload/fabu.png" alt="" /><span>发布</span></div></li>
                        <li onClick={() => { nav('/yinmeng/mine') }}><div className="l"><img src={location.pathname.includes('mine') ? 'https://www.pabzibo.live:3001/upload/mineck.png' : 'https://www.pabzibo.live:3001/upload//mine.png'} alt="" /><span>我的</span></div></li>
                    </ul>
                </div>

            </div>
        </div>
    )
}
