import { createSlice } from '@reduxjs/toolkit'
const demo = createSlice({
    name: 'demo',
    initialState: {

    }, reducers: {
        
    }
})
// export const { } = demo.actions
export default demo